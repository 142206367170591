import { Component, OnInit } from '@angular/core';
import { BlobModel } from 'src/app/Models/BlobModel';
import { Brand } from 'src/app/Models/BrandsModel';
import { UploadService } from 'src/app/Services/UploadService/upload.service';
import { ViewChild, ElementRef } from '@angular/core';
import { ResponseModel } from 'src/app/Models/ResponseModel';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})

export class UploadComponent implements OnInit {

  brandDirBlobs:BlobModel[];
  defaultDirBlobs:BlobModel[];
  newImagesList: File[];
  image;
  data;
  showSpinner: Boolean;
  brands: Brand[];
  allBrand: Brand[];
  types: string[];
  selectedBrand: string;
  selectedType: string;
  allBrandsDir: string;
  uploadResponses: ResponseModel[];

  processing: boolean;
  resultMessage: boolean;
  IsProducts: boolean;
  IsLogos: boolean;
  IsOther: boolean;
  allBrandsSelected: boolean;
  numProcessed: number;
  numTotal: number;
  @ViewChild('uploadBulk', {static:false}) bulkInputVar: ElementRef;
  @ViewChild('uploadToSelected', {static:false}) singleInputVar: ElementRef;

  constructor(private uploadService:UploadService) 
  {

  }

  async ngOnInit() 
  {  
    this.showSpinner = false;
    this.resultMessage = false;
    this.processing = true;
    this.getBrandList();
    this.selectedBrand = "All Brands";
    this.toggleSelectedUploadButton();
    this.selectLogosDir();
    this.refreshImageList();

  }

  async getBrandList(){
    this.brands = await this.uploadService.getBrandsData();
    this.allBrand = new Array<Brand>();
    this.allBrand.push(this.brands[0]);
    this.brands.shift();
  }

  sort() {
    const customSort = (a: BlobModel, b: BlobModel) => {
      const getBrandIndex = (url: string) => {
        const brandName = this.getNameFromURL(url).split('_Order_')[0];
        const index = this.brands.findIndex(i => i.brandName.replace('\'', "") === brandName);
        return index === -1 ? Number.MAX_SAFE_INTEGER : index;
      };
      const indexA = getBrandIndex(a.url);
      const indexB = getBrandIndex(b.url);
      if (indexA === indexB) return 1;
      return indexA - indexB;
    };

    const thenSort = (a: boolean, b: boolean) => {
      if (a !== b) return a ? -1 : 1;
      return 0;
    };

    this.brandDirBlobs
      .sort(customSort) //Order By Brand
      .sort((a, b) => thenSort(this.getNameFromURL(a.url).toLowerCase().includes('cup'), this.getNameFromURL(b.url).toLowerCase().includes('cup'))); //Order single cups then Group
  }
 

  getNameFromURL(url: string, index: number = 1) {
    var urlSplit = url.split("/");
    return urlSplit[urlSplit.length - index].split("?sv")[0].split("%27").join("").split("%20").join(" ");
  }

  clearFileInput(){
    if(this.bulkInputVar.nativeElement.value != undefined){
      this.bulkInputVar.nativeElement.value = "";
    }
    if(this.singleInputVar.nativeElement.value != undefined){
      this.singleInputVar.nativeElement.value = "";
    }
  }

  async uploadImagesBulk(event)
  {
    this.newImagesList = await event.target.files;
    this.startProcessingPopup(this.newImagesList.length);

    for(var i = 0; i < this.newImagesList.length; i++){
      var imgName = this.newImagesList[i].name;
      var imgBrand = this.getBrand(imgName);
      var imgType = this.getType(imgName);
      var defaultDir = this.getAllBrandType(imgType);
      var uploadResponse = new ResponseModel();
      uploadResponse.Name = imgName;
      uploadResponse.Message = " - File must be a png or jpg image file.";
      uploadResponse.HasError = true;

      if(this.isImageType(imgName)){
        var imgBtMp = await createImageBitmap(this.newImagesList[i]);
        var croppedFile = await this.resizeImg(imgBtMp, imgBrand+"_Order_"+imgName);

        uploadResponse.Message = " - Incorrect image name for bulk upload requirement.";

        if(!imgBrand.includes("error") && !imgType.includes("error") && !defaultDir.includes("error")){
          uploadResponse = await this.uploadService.uploadImage(defaultDir, imgBrand, imgType, croppedFile);
        }
      }

      this.uploadResponses.push(uploadResponse);

      this.numProcessed = this.uploadResponses.length;
      if(this.uploadResponses.length == this.newImagesList.length){
        this.refreshImageList();
        this.processing = false;
        this.showSpinner = false;
      }
    }
    
    this.clearFileInput();
  }

  async uploadImagesToSelectedBrand(event){
    this.newImagesList = await event.target.files;
    this.startProcessingPopup(this.newImagesList.length);

    for(var i = 0; i < this.newImagesList.length; i++){
      var imgName = this.newImagesList[i].name;
      var uploadResponse = new ResponseModel();
      uploadResponse.Name = imgName;
      uploadResponse.Message = " - File must be a png or jpg image file.";
      uploadResponse.HasError = true;

      if (this.isImageType(imgName)) {
        var imgBtMp = await createImageBitmap(this.newImagesList[i]);
        var croppedFile = await this.resizeImg(imgBtMp, this.selectedBrand + "_Order_" + imgName);

        uploadResponse = await this.uploadService.uploadImage(this.allBrandsDir, this.selectedBrand, this.selectedType, croppedFile);
      }

      this.uploadResponses.push(uploadResponse);

      this.numProcessed = this.uploadResponses.length;
      if(this.uploadResponses.length == this.newImagesList.length){
        this.refreshImageList();
        this.processing = false;
        this.showSpinner = false;
      }
    }

    this.clearFileInput();
  }

  async startProcessingPopup(numToProcess){
    this.numTotal = numToProcess;
    this.numProcessed = 0;
    this.uploadResponses = Array<ResponseModel>();
    this.processing = true;
    this.resultMessage = true;
    this.showSpinner = true;
  }

  async selectProductsDir(){

    this.IsProducts = true;
    this.IsOther = false;
    this.IsLogos = false;
    this.selectedType = "Product Images";
    this.allBrandsDir = "Default PI";
    this.refreshImageList();
  }

  async selectLogosDir(){
    
    this.IsLogos = true;
    this.IsOther = false;
    this.IsProducts = false;
    this.selectedType = "Logos";
    this.allBrandsDir = "Default Logos";
    this.refreshImageList();
  }

  async selectOtherDir() {

    this.IsOther = true;
    this.IsLogos = false;
    this.IsProducts = false;
    this.selectedType = "Other";
    this.allBrandsDir = "Default Other";
    this.refreshImageList();
  }

  updateSelectedBrand(brand){
    this.selectedBrand = brand;
    this.toggleSelectedUploadButton();
    this.refreshImageList();
  }

  getBrand(fName){
    var fNameSplit = Array<string>();
    var brand = "error";
    let namedBrand = "error";


    if(fName.includes(" Product ")){
      fNameSplit = fName.split(" Product ");
      namedBrand = fNameSplit[0];
      namedBrand = namedBrand.trim();
      for(let i = 0; i < this.brands.length; i++){
        if(this.brands[i].brandName == namedBrand){
          brand = namedBrand;
        }
      }
    }

    if(fName.includes(" Logo ")){
      fNameSplit = fName.split(" Logo ");
      namedBrand = fNameSplit[0];
      namedBrand = namedBrand.trim();
      for(let i = 0; i < this.brands.length; i++){
        if(this.brands[i].brandName == namedBrand){
          brand = namedBrand;
        }
      }
    }

    if (fName.includes(" Other ")) {
      fNameSplit = fName.split(" Other ");
      namedBrand = fNameSplit[0];
      namedBrand = namedBrand.trim();
      for (let i = 0; i < this.brands.length; i++) {
        if (this.brands[i].brandName == namedBrand) {
          brand = namedBrand;
        }
      }
    }

    return brand;
  }

  isImageType(fName : string){
    var lowCaseName = fName.toLowerCase();
    if(lowCaseName.includes(".jpg") || lowCaseName.includes(".png")){
      return true;
    }
    return false;
  }

  getType(fName){
    var type = "error";

    if(fName.includes(" Product ")){
      type = "Product Images";
    }

    if(fName.includes(" Logo ")){
      type = "Logos";
    }

    if (fName.includes(" Other ")) {
      type = "Other";
    }

    return type;
  }

  getAllBrandType(type){
    var allBrandsDir = type;

    if(type == "Product Images"){
      allBrandsDir = "Default PI";
    }

    if(type == "Logos"){
      allBrandsDir = "Default Logos";
    }

    if (type == "Other") {
      allBrandsDir = "Default Other";
    }


    return allBrandsDir;
  }

  async refreshImageList(){
    await this.delay(20);
    if(this.selectedBrand=="All Brands"){
      this.brandDirBlobs=await this.uploadService.getAllBrandsImageData(this.allBrandsDir);
    }else{
      this.brandDirBlobs=await this.uploadService.getProductImagesData(this.selectedBrand, this.selectedType);
    }
    this.getBrandList();
    this.sort();
  }

  closeResultMessageCheck(){
    if(this.processing == false){
      this.resultMessage = false;
      this.refreshImageList();
    }
  }

  async deleteImage(blob){
    this.uploadResponses = Array<ResponseModel>();
    this.resultMessage = true;
    this.uploadResponses = await this.uploadService.scanAllAndDelete(blob);
    this.processing = false;
    this.refreshImageList();
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async resizeImg(preImage, imgName){
    this.image = preImage;
    var canvas = await document.getElementById("canvas") as HTMLCanvasElement;
    var context = canvas.getContext("2d");
    this.data = {};

    canvas.width = this.image.width;
	  canvas.height = this.image.height;

	  context.drawImage(this.image, 0, 0, this.image.width, this.image.height);

    this.data = await context.getImageData(0, 0, this.image.width, this.image.height).data;
  
    var top = await this.scanY(true);
    var bottom = await this.scanY(false);
    var left = await this.scanX(true);
    var right = await this.scanX(false);
  
    var new_width = right - left;
    var new_height = bottom - top;

    canvas.width = new_width;
    canvas.height = new_height;
  
    await context.drawImage(this.image, left, top, this.image.width, this.image.height, 0, 0, this.image.width, this.image.height);

    return await this.createFileFromBlob(imgName);
  }

  async createFileFromBlob(imgName){
    const nImgCanvas = await document.querySelector("canvas");
    const blob = await new Promise(resolve => nImgCanvas.toBlob(resolve)) as BlobPart;
    return new File([blob], imgName, {type:"image/png"});
  }

  dataURItoBlob(dataURI) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
  }

  scanX(left) {
    var offset = (left) ? 1 : -1;
  
    for (var x = ((left) ? 0 : this.image.width - 1); ((left) ? (x < this.image.width) : (x > -1)); x += offset) {
      for (var y = 0; y < this.image.height; y++) {
        var isWhiteLine = this.isWhite(this.getRGB(x,y));
        var isBlackLine = this.isBlack(this.getRGB(x,y));
        if (!isBlackLine && !isWhiteLine) {
          return x;
        }
      }
    }
  
    return null;
  }

  scanY(top) {
    var offset = (top) ? 1 : -1;
  
    for (var y = ((top) ? 0 : this.image.height - 1); ((top) ? (y < this.image.height) : (y > -1)); y += offset) {
      for (var x = 0; x < this.image.width; x++) {
        var isWhiteLine = this.isWhite(this.getRGB(x,y));
        var isBlackLine = this.isBlack(this.getRGB(x,y));
        if (!isBlackLine && !isWhiteLine) {
          return y;
        }
      }
    }
  
    return null;
  }

  isWhite(rgb) {
    return rgb.red > 210 && rgb.green > 210 && rgb.blue > 210;
  }

  isBlack(rgb) {
    return rgb.red < 10 && rgb.green < 10 && rgb.blue < 10;
  }

  getRGB(x, y) {
    return {
      red: this.data[((this.image.width * y) + x) * 4],
      green: this.data[((this.image.width * y) + x) * 4 + 1],
      blue: this.data[((this.image.width * y) + x) * 4]
    };
  }

  toggleSelectedUploadButton(){
    this.allBrandsSelected = this.selectedBrand == "All Brands"? true:false;
  }
}
