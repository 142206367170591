import { Injectable } from '@angular/core';
import { AzureBlobService } from '../AzureBlobServices/azure-blob.service';
import { azureEndPoints } from 'src/app/AzureConfig/AzureEndPoints';
import { HttpClient } from '@angular/common/http';
import {BlobServiceClient } from "@azure/storage-blob";
import { BlobModel } from '../../Models/BlobModel';
import { Brand } from 'src/app/Models/BrandsModel';
import { ResponseModel } from 'src/app/Models/ResponseModel';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  uploadStagingFolderName: string = "";
  constructor(private azureBlobService:AzureBlobService,private http:HttpClient) 
  { 
   
  }

  async getBrandsData(){

    var BrandsList=new Array<Brand>();
    var blobsList;
    var brandNameList=new Array<string>();
    var brandsList = new Array<string>();

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result)=>{
      blobsList=result;  
    })
  
    blobsList.forEach(element => {
      if(element.split('/')[0]=="Brands")
      brandsList.push(element);  
     });

    brandNameList.push("All Brands");

    brandNameList.push("Coca-Cola");
    brandNameList.push("Coca-Cola Zero Sugar");
    brandNameList.push("Diet Coke");
    brandNameList.push("Sprite");
    brandNameList.push("Coca-Cola Cherry");
    brandNameList.push("Dr Pepper");
    brandNameList.push("Pibb Xtra");
    brandNameList.push("Fanta Orange");
    brandNameList.push("Minute Maid");

    brandNameList.push("Sprite Zero");
    brandNameList.push("Diet Dr Pepper");
    brandNameList.push("Mello Yello");
    brandNameList.push("Barq's");
    brandNameList.push("Seagram's Ginger Ale");

    brandNameList.push("Dasani");
    brandNameList.push("Smartwater");

    brandNameList.push("Monster");
    brandNameList.push("NOS");
    brandNameList.push("Full throttle");

    brandNameList.push("Sun Drop");
    brandNameList.push("Powerade");
    brandNameList.push("Gold Peak Tea");
    brandNameList.push("Tum-E-Yummies");
    brandNameList.push("Fairlife");

     brandNameList.forEach((itemBrandName)=>{
    
      var graphicsSingleFolder= brandsList.filter((ele)=>ele.split('/')[1]==itemBrandName)
    
      var brandItem=new Brand();
      brandItem.brandName = itemBrandName;
      if (itemBrandName == "All Brands")
        brandItem.checked = true;
      else
      brandItem.checked = false; // By Sanjiv - US96434 - AllBrands
      brandItem.brandLogos=new Array<string>();
      brandItem.brandProducts = new Array<string>();
      brandItem.other = new Array<string>();
      brandItem.brandUberEats = new Array<string>();
      brandItem.brandDoorDash = new Array<string>();
      brandItem.brandGrubhub = new Array<string>();

      graphicsSingleFolder.forEach((item)=>{
        if(item.split('.')[1]=="png"|| item.split('.')[1]=="jpg"){

        var brandSpacificFolder=item.split('/')[2];

        if (brandSpacificFolder == 'Product Images') {

            var itemUrl = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, item)
            brandItem.brandProducts.push(itemUrl)
          }
        else if(brandSpacificFolder =='Logos'){
       
          var itemUrl=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,item)
          brandItem.brandLogos.push(itemUrl)
          }
        else if (brandSpacificFolder == 'Other') {

          var itemUrl = this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer, item)
          brandItem.other.push(itemUrl)
        }
      }
      
      })

      BrandsList.push(brandItem);
     })
  
    return  BrandsList;
  }

  async getProductImagesData(brand: string, type: string){
    var blobsList;
    var ProductsImagesList=new Array<BlobModel>();

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result)=>{
      blobsList=result;  
    })

    blobsList.forEach(element => {
      if(element.split('/')[0]=="Brands" && element.split('/')[1]==brand && element.split('/')[2]==type){
        var elementURL=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,element)
        ProductsImagesList.push({url: elementURL, name: element});  
      }
    });
    return ProductsImagesList;
  }

  async getAllBrandsImageData(type: string){
    var blobsList;
    var ProductsImagesList=new Array<BlobModel>();

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result)=>{
      blobsList=result;  
    })

    blobsList.forEach(element => {
      if(element.split('/')[0]==type){
        var elementURL=this.azureBlobService.loadBlob(azureEndPoints.azureBlobs.graphicsContainer,element)
        ProductsImagesList.push({url: elementURL, name: element}); 
      }
    });
    return ProductsImagesList;
  }

  async uploadImage(defaultDir: string, brandDir: string, typeDir:string, file: File) : Promise<ResponseModel> {
    
    const account = azureEndPoints.azureBlobs.accName;
    const sas = "?sr=b&"+azureEndPoints.BLOBSASToken;
    
    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);

    let containerClient = blobServiceClient.getContainerClient(azureEndPoints.azureBlobs.graphicsContainer + "/Brands/" + brandDir + "/" + typeDir);
    
    var blobClient = containerClient.getBlockBlobClient(file.name);
    var options = { blobHTTPHeaders: { blobContentType: file.type } };
    var uploadResponseToBrand = await blobClient.uploadData(file, options);

    containerClient = blobServiceClient.getContainerClient(azureEndPoints.azureBlobs.graphicsContainer + "/" + defaultDir);

    blobClient = containerClient.getBlockBlobClient(file.name);
    options = { blobHTTPHeaders: { blobContentType: file.type } };
    var uploadResponseToDefault = await blobClient.uploadData(file, options);

    var response = new ResponseModel(); 
    response.Name = file.name;
    response.Message = " - Upload was not complete - error";
    response.HasError = true;

    if(uploadResponseToBrand._response.status == 201 && uploadResponseToDefault._response.status == 201){
      response.Message = " - Uploaded successfully";
      response.HasError = false;
    }

    return response;
  }

  async scanAllAndDelete(blob) : Promise<ResponseModel[]> {
    var blobsList;
    var imgNameParts = blob.name.split("/");
    var imgName = imgNameParts[imgNameParts.length-1];
    var responseList = Array<ResponseModel>();

    const account = azureEndPoints.azureBlobs.accName;
    const sas = "?sr=b&" + azureEndPoints.BLOBSASToken;
    
    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);

    let containerClient = blobServiceClient.getContainerClient(azureEndPoints.azureBlobs.graphicsContainer);

    await this.azureBlobService.getAllBlobs(azureEndPoints.azureBlobs.graphicsContainer).then((result)=>{
      blobsList=result;  
    })

    blobsList.forEach(async element => {
      if(element.includes(imgName)){
        var responseNum = await (await containerClient.deleteBlob(element))._response.status;
        var response = new ResponseModel();
        response.Name = element;
        if (responseNum == 202) {
          response.Message = " - Deleted successfully";
          responseList.push(response);
        }else{
          response.Message = " - Delete was not complete - error";
          response.HasError = true;
          responseList.push(response);
        }
      }
    });

    return responseList;
  }
}












