import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule,Router} from '@angular/router';
import { FormsModule} from '@angular/forms';
import{HttpClientModule} from '@angular/common/http'

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { GraphicsComponent } from './components/graphics/graphics.component';
import { UploadComponent } from './components/upload/upload.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { MenuDetailsComponent } from './components/menu-details/menu-details.component';
import { CustomersService } from './Services/CustomersService/customers.service';
import { HomeComponent } from './components/home/home.component';
import { AzureBlobService } from './Services/AzureBlobServices/azure-blob.service';
import { DirectAccessGuard } from './app-routing/DirectAccessGuard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MenusListComponent } from './components/menus-list/menus-list.component';
import { DataService } from './Services/DataService/data.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { GraphicsService } from './Services/GraphicsService/graphics.service';

import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { azureEndPoints } from 'src/app/AzureConfig/AzureEndPoints';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

export function MSALInstanceFactory(): IPublicClientApplication{
  return new PublicClientApplication(
    {
      auth:{
        clientId:azureEndPoints.azureAuth.clientId,
        redirectUri: azureEndPoints.azureAuth.redirectUri,
        authority: azureEndPoints.azureAuth.authority
      }
    }
  )
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    MenusListComponent,
    GraphicsComponent,
    UploadComponent,
    MenuDetailsComponent,
    HomeComponent,
    NotFoundComponent,
    MenusListComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    RecaptchaModule,
    MsalModule,
    BrowserAnimationsModule,
    MatTooltipModule
  ],
  providers: [DirectAccessGuard,CustomersService,
    AzureBlobService,DataService,GraphicsService,{
      provide:MSAL_INSTANCE,
      useFactory:MSALInstanceFactory
    },
  MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
