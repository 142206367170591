import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import {AuthenticationResult} from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { azureEndPoints } from './AzureConfig/AzureEndPoints';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  isHomeVisible:boolean;
  userName:string;
  storageEvent = new Event('storage');

  constructor(private router: Router, private msalService: MsalService)
  {
    this.routeEvent(this.router);
  }

  navigateToHome(){
    this.router.navigate(['home']);
  }

  routeEvent(router: Router){
    router.events.subscribe(async e => {
      if(e instanceof NavigationEnd)
      {
        this.updateUserRole();
        this.router.navigated = false;

        if(e.url === '/login'|| e.url === '/home')
        {
          this.isHomeVisible=false;
        }
        else if(e.url === '/menus'|| e.url === '/menuDetails' || e.url === '/graphics' || e.url === '/upload')
        {
          this.isHomeVisible=true;
        }

      }
    });
  }

signIn(){
  this.msalService.loginPopup().subscribe( (response: AuthenticationResult)=> {
    this.msalService.instance.setActiveAccount(response.account)
    this.updateUserRole();
    window.dispatchEvent(this.storageEvent);
    location.replace(azureEndPoints.azureAuth.redirectUri);
    this.getUserName();
  });
}

signOut(){
  this.msalService.logout();
  this.updateUserRole();
  window.dispatchEvent(this.storageEvent);
  location.replace(azureEndPoints.azureAuth.redirectUri);
}

getUserName(){
  this.userName = "You are signed-in as " + this.msalService.instance.getActiveAccount().name;
}

isLoggedIn(): boolean{
  
  return this.msalService.instance.getActiveAccount() != null 
}

updateUserRole(){
  if (this.msalService.instance.getActiveAccount() !== null && this.msalService.instance.getActiveAccount() !== undefined) {

    var claims = this.msalService.instance.getActiveAccount()?.idTokenClaims;

    sessionStorage.setItem('roles', claims['roles']);
    
    this.getUserName();

  }else{
    sessionStorage.setItem('roles', undefined);
  }
}

}
